import React, { FC } from "react";
import { DatePicker, DateValidationError, PickerChangeHandlerContext } from "@mui/x-date-pickers";
// import { TextField } from "@mui/material";

interface CustomDatePickerProps {
    label?: React.ReactNode;
    value?: any;
    onChange: (value: any, context: PickerChangeHandlerContext<DateValidationError>) => void;
    minDate?: any;
    maxDate?: any;
}

const CustomDatePicker: FC<CustomDatePickerProps> = ({ label, value, onChange, maxDate, minDate }) => (
    <DatePicker
        label={label}
        format="YYYY-MM-DD"
        value={value}
        onChange={onChange}
        desktopModeMediaQuery="@media (min-width: 540px)"
        maxDate={maxDate}
        minDate={minDate}
    />
)

export default CustomDatePicker;